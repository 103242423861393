footer {
  padding-top: 72px;
  padding-bottom: 36px;

  section {
    margin-bottom: 36px;
  }

  .logo-desc {
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    max-width: 320px;
  }

  .header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 18px;
  }

  i {
    color: white !important;
  }

  i {
    font-size: 16px;
    margin-right: 12px;
  }

  a {
    color: white !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .link-text {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .terms-condition-parent {
    a {
      font-weight: 400;
      margin-right: 24px;
      margin-bottom: 0px;
    }
  }
}
