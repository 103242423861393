@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/dimensions';

h1 {
  font-size: 35px;
  font-weight: 500;
  line-height: 110%;

  @include media-breakpoint-up(md) {
    font-size: 50px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 55px;
  }
}

h2 {
  font-size: 35px;
  font-weight: 500;
  line-height: 110%;

  @include media-breakpoint-up(md) {
    font-size: 50px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 55px;
  }
}

h3 {
  font-size: 35px;
  font-weight: 400;
  line-height: 110%;
  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 45px;
  }
}

.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 30px;
  margin-top: 30px;
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.text-justify {
  text-align: justify;
}

.logo-image {
  svg {
    height: 40px;
    @include media-breakpoint-up(md) {
      height: 48px;
    }
    @include media-breakpoint-up(lg) {
      height: 56px;
    }
  }
}

// =============== New Custom Styles ===============
.full-height {
  min-height: 100vh;
  max-height: 100vh;
}

.divider {
  height: 1px;
  background: $primary;
}

.fixed-position-full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.navbar-padding {
  padding-top: $navbar-height-sm;

  @include media-breakpoint-up(md) {
    padding-top: $navbar-height-md;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $navbar-height-lg;
  }
}
