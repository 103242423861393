@import '../../styles/variables';

.navbar {
  border: solid 1px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  background: white !important;
  background-color: white !important;

  .nav-collapse-button {
    i {
      font-size: 22px;
    }
  }

  a {
    color: $secondary;

    font-size: 16px;
    font-weight: 500;

    line-height: 150%;
    text-decoration: none;

    margin-right: 20px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .navbar-nav {
    --bs-scroll-height: 280px;
  }

  .active {
    color: $primary !important;
    text-decoration: none;
  }

  .flag-selected {
    border-bottom: solid 3px $primary !important;
    box-sizing: border-box;
  }

  .flag-not-selected {
    border-bottom: solid 3px white !important;
    box-sizing: border-box;
  }
}
