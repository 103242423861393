@import '../../../styles/variables';
@import '../../../styles/mixins';

.contact-info-container {
  min-height: 60vh;

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
  }

  .contact-info {
    @include media-breakpoint-up(lg) {
      min-height: 640px;
    }
  }

  .social-icons {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: $primary;
    border: solid 1px $primary;

    i {
      margin-top: -7px;
      font-size: 18px;
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: white;
      i {
        color: $primary !important;
      }
    }
  }

  .link-text {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;

    i {
      color: $primary;
      font-size: 18px;
    }

    a {
      margin-bottom: 0px;
      text-decoration: none;
    }
  }
}
