@import '../../../styles/variables';

.success-story-panel {
  .success-intro-pic-parent {
    width: 100%;
  }
  .success-intro-pic {
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    border-radius: 10px;
    object-fit: cover;
  }

  .success-block {
    background: $secondary;
    color: $primary;
    border-radius: 10px;
    height: 153px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 45px;
      font-weight: 600;
    }

    .detail {
      font-size: 45px;
      opacity: 0.8;
      font-size: 24px;
    }
  }
}
