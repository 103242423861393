.consent-modal {
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;

  background: white;
  box-sizing: content-box;

  .consent-text {
    font-size: 12px;
    line-height: 14px;
  }

  button {
    min-width: 100px;
  }
}
