@import '../../../styles/colors';
@import '../../../styles/variables';

.testimonial-panel {
  background: $primary-op;
  padding: 75px 0;
  margin-bottom: 75px;
}
.testimonial {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  // box-shadow: 5px 0 25px $primary-boxshadow;
  border: solid 1px rgba(0, 0, 0, 0.08);
  margin: 30px;
  font-weight: 400;
  line-height: 150%;
  height: 250px;

  .text {
    color: $secondary;
    font-size: 18px;
    opacity: 0.7;
  }

  .name {
    color: $secondary;
    font-size: 18px;
    font-weight: 500;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

  .quote-parent {
    position: absolute;
    top: -20px;
    left: 40px;

    width: 40px;
    height: 40px;

    background: $primary;
    border-radius: 50%;

    text-align: center;

    i {
      font-size: 24px;
      color: $secondary;
    }
  }
}
