@import '../../styles/mixins';
@import '../../styles/colors';

.service-page {
  img {
    object-fit: cover;
  }
  .section-header {
    height: 50vh;
    @include media-breakpoint-up(md) {
      height: 30vh;
    }
    @include media-breakpoint-up(lg) {
      height: 50vh;
    }

    .description {
      max-width: 750px;
    }

    .bg-transparent-secondary {
      background: #28232999;
    }
  }

  .section-detail {
    font-size: 16px;
    white-space: pre-wrap;
    font-weight: 500;
  }

  .service-list {
    padding-top: 75px;
    padding-bottom: 75px;
    max-width: 1050px;

    .service-item {
      height: 460px;
      background: #fff;
      border-radius: 10px;
      border: solid 1px rgba(0, 0, 0, 0.08);

      margin-bottom: 32px;

      .service-item-text-wrapper {
        padding: 32px 16px;
      }

      img {
        width: 100%;
        height: 240px;
        min-width: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 150%;
      }

      .detail {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;

        opacity: 0.9;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .learn-more-button {
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;

        i {
          margin-left: 2px;
        }

        &:hover {
          text-decoration: none;
          i {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
