@import '../../../styles/mixins';
@import '../../../styles/variables';

.about-page {
  .about-panel {
    min-height: 50vh;
    margin-bottom: 50px;

    .description {
      font-size: 16px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 100vh;
      margin-bottom: 0px;
    }

    .about-content {
      background-image: linear-gradient(
        to right,
        white,
        white,
        white,
        transparent
      );

      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      min-height: 100vh;

      object-fit: cover;
      width: 80%;

      z-index: 0;
    }
  }
}
