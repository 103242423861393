@import '../../../styles/variables';
@import '../../../styles/mixins';

.contact-info-form {
  .success-container {
    z-index: 100;
  }

  .check-container {
    border-radius: 50%;
    background: $success;
    height: 80px;
    width: 80px;
    .bi-check-lg {
      font-size: 48px;
    }
  }

  input,
  textarea {
    color: $secondary;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .input-error {
    border: solid 1px $danger;
  }

  .input-error-text {
    color: $danger;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 4px;
  }

  label {
    padding-left: 24px !important;
    color: $secondary !important;

    &:focus {
      color: $secondary !important;
    }

    &::after {
      background-color: transparent !important;
    }
  }
}
