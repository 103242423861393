@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.team-panel {
  padding: 100px 0 75px 0;

  .team-member {
    margin-bottom: 32px;

    img {
      width: 100%;
      height: 360px;
      min-width: 100%;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .content {
      background: $primary-op;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 100%;
      height: 190px;
      padding: 20px 10px 30px;
    }

    .name {
      color: $secondary;
      font-size: 22px;
      font-weight: 400;
      line-height: 150%;
    }

    i {
      font-size: 22px;
      margin: 0 8px;
    }

    .role {
      opacity: 0.5;
    }
  }
}
