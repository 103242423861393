@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.job-detail-modal {
  outline: none !important;

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 24px;

  overflow: hidden;

  @include media-breakpoint-up(md) {
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
  }

  @include media-breakpoint-up(lg) {
    top: 10%;
    left: 50%;
    right: auto;
    bottom: 10%;

    width: 100%;
    max-width: 1200px;

    margin-right: -50%;
    transform: translate(-50%, 0%);

    padding: 36px;
  }

  background: white;
  border-radius: 20px;

  .job-description {
    margin-top: 32px;
    font-size: 16px;
    line-height: 20px;
    opacity: 80%;
  }

  .job-detail-modal-container {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .close-button-container {
    i {
      font-size: 32px;
    }
  }

  h3 {
    margin-left: 70px;
  }

  .divider {
    margin-top: 32px;
    margin-bottom: 32px;

    min-height: 1px;
    max-height: 1px;

    background-color: $secondary;
    opacity: 20%;

    width: 100%;
  }

  h3 {
    font-size: 32px;
    line-height: 46px;
    font-weight: 500;
  }

  .job-detail-view {
    color: $secondary;
    font-size: 14px;
    line-height: 16px;
    .value {
      color: $secondary;
      font-weight: 500;
    }
  }

  .job-points-view {
    .title {
      margin-top: 32px;
      margin-bottom: 16px;

      font-size: 24px;
      line-height: 40px;
      font-weight: 500;
    }

    li {
      margin-bottom: 8px;

      font-size: 16px;
      line-height: 20px;

      opacity: 80%;
    }
  }

  .apply-button {
    max-width: 320px;
  }
}
