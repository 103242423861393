@import '../../../styles/colors';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.home-page {
  .introduction-slider-panel {
    min-height: 100vh;
    max-height: 100vh;

    .carousel {
      max-width: 100vw;

      .carousel.carousel-slider .control-arrow:hover {
        background: transparent !important;
      }

      &:nth-child(1) {
        max-height: 100%;
        max-width: 100%;
      }
      &:nth-child(2) {
        top: -180px;
      }

      .item-container,
      li {
        max-height: 100vh;
      }

      img {
        min-width: 100%;
        max-width: 100%;
        // min-width: 75vw;
        // max-width: 75vw;
        min-height: 100vh;
        max-height: 100vh;

        object-fit: cover;
      }

      .text-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.7) 100%
        );

        & > h2 {
          color: $secondary;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}
