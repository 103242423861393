@import '../../../styles/colors';

.search-banner {
  background: $primary-op;
  .search-input-container {
    background: white;
    box-shadow: 0px 2px 2px rgba(28, 36, 51, 0.1);
    border-radius: 8px;
    max-width: 600px;
    .search-input {
      border: none;
      font-size: 24px;
      // border-bottom: solid 1px rgba(0, 0, 0, 0.08);
      &:focus {
        border: none;
        outline: none;
        // border-bottom: solid 1px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
