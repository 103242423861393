.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 1100;
}

.btn-primary {
  color: #ffffff;
  &:hover {
    background: transparent;
    color: $primary;
  }
}

.btn-outline-primary {
  font-size: 1.25rem;
  &:hover {
    color: #ffffff;
  }
}
