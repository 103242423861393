@import '../../styles/colors';
@import '../../styles/variables';

.job-board-page {
  min-height: 100vh;

  .job-card {
    .logo {
      width: 56px;
      height: 56px;
      object-fit: fill;
      border-radius: 10px;
      i {
        font-size: 32px;
      }
    }

    .name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    .company-name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    .detail {
      font-size: 16px;
      line-height: 24px;
      i {
        margin-right: 8px;
      }
    }

    a {
      text-decoration: none;
    }
  }
}
