@import '../../../styles/variables';

.home-page {
  .services-intro-panel {
    .service-intro-pic-panel {
      .pic-container {
        width: 80%;
      }
      .bg-shadow {
        background: $primary;
        height: 97%;
        width: 97%;
        object-fit: cover;
        border-radius: 10px;
        position: absolute;
        margin-left: 6%;
      }

      .service-intro-pic {
        height: 97%;
        width: 97%;
        border-radius: 10px;
        position: absolute;
        margin-top: 6%;
        object-fit: cover;
      }
    }

    .service-link {
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 23px;
      text-decoration: none;

      &:hover {
        font-weight: 600;
        color: $primary;
        cursor: pointer;
      }
    }
  }
}
